import React, { useEffect, useState, createContext } from "react";
import { loadMoov } from "@moovio/moov-js";

export const MoovContext = createContext({
  moov: null,
  setMoov: () => {},
  getToken: () => {}
});

export default function MoovContextProvider({ children }) {
  const [moov, setMoov] = useState(null);

  async function getToken(accountID, action) {
    const url = accountID && action ? `/token?accountID=${accountID}&action=${action}` : "/token";
    const data = await fetch(url);
    const json = await data.json();
    return json.token;
  }

  useEffect(() => {
    // this isn't great, is it?
    (async () => {
      getToken().then(token => {
        loadMoov(token).then((moov) => {
          setMoov(moov);
        });
      });
    })();
  }, []);

  return (
    <MoovContext.Provider
      value={{
        moov: moov,
        setMoov: setMoov,
        getToken: getToken
      }}
    >
      {children}
    </MoovContext.Provider>
  );
}