import React, { lazy, Suspense, useState, useEffect } from "react";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import { Hook, Unhook, Console } from "console-feed"
import './App.css';
import infoIcon from './img/info_outlined.png';
import errorIcon from './img/error.png';
import warnIcon from './img/warning.png';
import Header from './components/header/Header';
import MoovContextProvider from "./contexts/MoovContext";

const CreateAccount = lazy(() => import('./components/create-account/CreateAccount'));
const PaymentMethods = lazy(() => import('./components/payment-methods/PaymentMethods'));
const Confirmation = lazy(() => import('./components/confirmation/Confirmation'));
const Payout = lazy(() => import('./components/payout/Payout'));
const Checkout = lazy(() => import('./components/checkout/Checkout'));
const CheckoutPayment = lazy(() => import('./components/checkout/Payment'));
const ApplePayment = lazy(() => import('./components/apple-pay/ApplePayment'));

function App() {
  const location = useLocation();
  const [logs, setLogs] = useState([]);
  const [consoleOpened, setConsoleOpened] = useState(false);
  const [section, setSection] = useState('app');

  useEffect(() => {
    Hook(
      window.console,
      (log) => setLogs((currLogs) => [...currLogs, log]),
      false
    );
    return () => Unhook(window.console)
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith('/checkout') || location.pathname.startsWith('/apple-pay')) {
      setSection('checkout')
    } else {
      setSection('app')
    }
  }, [location])

  const toggleConsole = () => {
    setConsoleOpened((prevState) => !prevState);
  }

  return (
    <div className={consoleOpened ? `${section} ${section}-console` : section}>
      <MoovContextProvider>
        <Suspense fallback={<div className="spinner-container"><div className="spinner"></div></div>}>
          <Routes>

            <Route path="/" element={
              <>
                <Header />
                <main>
                  <div className="container">
                    <Outlet />
                  </div>
                </main>
              </>
            }>
              <Route index element={<CreateAccount />} />
              <Route path="link-payment-method" element={<PaymentMethods />} />
              <Route path="account" element={<Payout />} />"
              <Route path="confirmation" element={<Confirmation />} />
            </Route>

            <Route path="checkout" element={<Checkout />}>
              <Route index element={<CheckoutPayment />} />
            </Route>

            <Route path="apple-pay" element={<Checkout />}>
              <Route index element={<ApplePayment />} />
            </Route>

          </Routes>
        </Suspense>
      </MoovContextProvider>
      <aside className="console">
        <header className="console-header">Moov Console</header>
        <Console 
          logs={logs}
          variant="dark"
          styles={{
            BASE_FONT_FAMILY: '"JetBrains Mono", monospace',
            BASE_FONT_SIZE: '14px',
            BASE_LINE_HEIGHT: 1.5,
            TREENODE_LINE_HEIGHT: 1.5,
            BASE_BACKGROUND_COLOR: 'transparent',
            OBJECT_NAME_COLOR: '#fff',
            OBJECT_VALUE_STRING_COLOR: '#6FCF97',
            OBJECT_VALUE_NUMBER_COLOR: '#2D9CDB',
            OBJECT_VALUE_BOOLEAN_COLOR: '#BB6BD9',
            LOG_ICON_WIDTH: '12px',
            LOG_ICON_HEIGHT: '12px',
            LOG_ICON_BACKGROUND_SIZE: '12px 12px',
            LOG_INFO_COLOR: '#828282',
            LOG_INFO_ICON: `url(${infoIcon})`,
            LOG_ERROR_ICON: `url(${errorIcon})`,
            LOG_WARN_ICON: `url(${warnIcon})`,
          }}
        />
      </aside>
      <button type="button" onClick={toggleConsole} className="console-toggle">
        {consoleOpened ? 'Close' : 'Console'}
      </button>
    </div>
  );
}

export default App;
