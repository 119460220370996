import './Header.css';
import logo from "../../img/logo.png";

function Header() {
  return (
    <header className="site-header">
      <div className="container">
        <div className="logo">
          <img src={logo} alt="" />
          Classbooker
        </div>
        <div className="description">
          Classbooker is a fictitious SaaS platform built on <a href="https://moov.io">Moov</a>. Sign up as a business to step through account creation and linking a payment method.
        </div>

        <a href="https://moov.io" className="cta">
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m20.71 3.72-.43-.43a1 1 0 0 0 -.89-.29l-5.5 1a3.89 3.89 0 0 0 -2.36 1.41l-2.16 2.78a3 3 0 0 0 -3.46 1.09l-2.82 4a.5.5 0 0 0 .12.69l.41.29a3 3 0 0 0 4.18-.74l.63-.89 2.91 2.91-.89.63a3 3 0 0 0 -.74 4.18l.29.41a.5.5 0 0 0 .69.12l4-2.82a3 3 0 0 0 1.09-3.46l2.75-2.16a3.89 3.89 0 0 0 1.47-2.33l1-5.5a1 1 0 0 0 -.29-.89zm-4.15 5.84a1.5 1.5 0 1 1 0-2.12 1.49 1.49 0 0 1 0 2.12zm-8.74 7.32a.5.5 0 0 1 0 .71l-3.26 3.26a.5.5 0 0 1 -.71 0l-.7-.7a.5.5 0 0 1 0-.71l3.26-3.26a.5.5 0 0 1 .71 0z"/></svg>
          Find out how to add money movement to your app using&nbsp;Moov.
        </a>
      </div>
    </header>
  )
}

export default Header;